import { Record } from 'immutable'

const ManufacturerRecord = Record({
  ManufacturerID: '',
  ManufacturerName: '',
  ManufacturerState: '',
  SNLength: null,
})

export default class Manufacturer extends ManufacturerRecord {
  get id() {
    return this.ManufacturerID
  }
  get label() {
    return `${this.ManufacturerName} - ${this.ManufacturerState}`
  }
}
