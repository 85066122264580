import Immutable from "immutable";
import * as types from "../actions/homeSearch.js";
import RetailerRecord from "../../retailer/retailer.js";
import ManufacturerRecord from "../../manufacturer/manufacturer.js";
import HomeRecord from "../../home/home.js";

export const HomeSearchState = Immutable.Record({
  // eslint-disable-line new-cap
  retailers: Immutable.List([]),
  manufacturers: Immutable.List([]),
  homes: Immutable.List([]),
  loading: false,
});

const initialState = new HomeSearchState();

const handlers = {
  [types.GET_RETAILERS]: (state, action) => {
    const retailer = action.payload;

    return state.set(
      "retailers",
      Immutable.List(retailer.map((r) => new RetailerRecord(r)))
    );
  },
  [types.GET_MANUFACTURERS]: (state, action) => {
    const manufacturer = action.payload;
    return state.set(
      "manufacturers",
      Immutable.List(manufacturer.map((m) => new ManufacturerRecord(m)))
    );
  },
  [types.GET_HOMES]: (state, action) => {
    const homes = action.payload;

    return state
      .set("homes", Immutable.List(homes.map((h) => new HomeRecord(h))))
      .set("loading", false);
  },
  [types.START_LOADING]: (state) => state.set("loading", true),
  [types.RESET_HOMES]: (state, action) =>
    state.set("homes", Immutable.List([])),
};

export default function homeSearchReducer(state = initialState, action) {
  if (handlers.hasOwnProperty(action.type)) {
    console.log("true");

    return handlers[action.type](state, action);
  } else {
    console.log("false");

    return state;
  }
}
