import { Component } from 'react'

// import { Router } from 'react-router'
// import {createBrowserHistory} from 'history/index.js'
import routes from './routes.js'

import { Provider } from 'react-redux'
import store from './redux/store/index.js'

import {BrowserRouter as Router} from 'react-router-dom';


// const history = createBrowserHistory();
// console.log('history', history.location.pathname);

class App extends Component {
  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <Router>{routes}</Router>
        </Provider>
      </div>
    )
  }
}

export default App
