import { Navigate } from 'react-router-dom';
import Component from '../components/component.react.js'
import React from 'react'

// Higher order component.
// https://medium.com/@dan_abramov/mixins-are-dead-long-live-higher-order-components-94a0d2f9e750
export default function requireAuth(BaseComponent) {
  class Authenticated extends Component {
    // static willTransitionTo(transition) {
    //   const activeUser = Component.getActiveUserRecord();
    //   console.log("Authenticated Class activeUser", activeUser);
    //   if (
    //     !activeUser ||
    //     !(activeUser.group === "Manufacturer" || activeUser.group === "Admin")
    //   ) {
    //     transition.redirect(
    //       "/login",
    //       {},
    //       {
    //         nextPath: transition.path,
    //       }
    //     );
    //   }
    // }

    // render() {
    //   console.log("requireAuth props", this.props);
    //   return <BaseComponent {...this.props} />;
    // }

    render() {
      const activeUser = Component.getActiveUserRecord();
      // console.log("Authenticated Class activeUser", activeUser);

      // If the user is not authenticated, redirect to the login page
      if (
        !activeUser ||
        !(activeUser.group === "Manufacturer" || activeUser.group === "Admin")
      ) {
        const nextPath = window.location.pathname;
        return (
          <Navigate
            to={`/login?nextPath=${encodeURIComponent(nextPath)}`}
            replace
          />
        );
      }

      // If the user is authenticated, render the base component
      return <BaseComponent {...this.props} />;
    }
  }

  Authenticated.displayName = `${BaseComponent.name}Authenticated`

  return Authenticated
}
