import { combineReducers } from 'redux'
import homeSearch from './homeSearch.js'
import home from './home.js'
import users from './users.js'
// import form from './form'

export default combineReducers({
  homeSearch,
  home,
  users,
  // form
})
