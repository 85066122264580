import App from "./app/app.react.js";
import Mfgsearch from "./pages/mfgsearch.react.js";
import HomesearchPage from "./pages/homesearchpage.react.js";
import WelcomePage from "./pages/welcomepage.react.js";
import ViewHome from "./pages/viewhome.react.js";
import AddHome from "./pages/addhome.react.js";
import CertificatePage from "./pages/certificatepage.react.js";
import Login from "./pages/login.react.js";
import NotFound from "./pages/notfound.react.js";
import { Routes, Route } from "react-router-dom";
// import { Route } from "react-router";
// import Component from "./components/component.react.js";

// const requireAuth = (location, replaceWith) => {
//   console.log("location", window.location);
//   const activeUser = Component.getActiveUserRecord();
//   if (
//     !activeUser ||
//     !(activeUser.group === "Manufacturer" || activeUser.group === "Admin")
//   ) {
//     replaceWith(null, "/login", { nextPath: window.location.pathname });
//   }
// };

export default (
  // <Route component={App}>
  //   <Route onEnter={requireAuth}>
  //     <Route component={WelcomePage} path="/" />
  //     <Route component={ViewHome} name="/viewhome">
  //       <Route component={ViewHome} path="/viewhome/:homeId" />
  //     </Route>
  //     <Route component={AddHome} path="/addhome" />
  //     <Route component={HomesearchPage} path="/searchhomes" />
  //     <Route component={Mfgsearch} path="/mfgsearch" />
  //     <Route component={CertificatePage} path="/certificate/:homeId" />
  //   </Route>
  //   <Route component={Login} path="/login" />
  //   <Route path="*" component={NotFound} />
  // </Route>

  <Routes>
    <Route
      element={
        <App>
          <WelcomePage />
        </App>
      }
      path="/"
    />
    <Route
      element={
        <App>
          <ViewHome />
        </App>
      }
      name="/viewhome"
    >
      <Route
        element={
          <App>
            <ViewHome />
          </App>
        }
        path="/viewhome/:homeId"
      />
    </Route>
    <Route
      element={
        <App>
          <AddHome />
        </App>
      }
      path="/addhome"
    />
    <Route
      element={
        <App>
          <HomesearchPage />
        </App>
      }
      path="/searchhomes"
    />
    <Route
      element={
        <App>
          <Mfgsearch />
        </App>
      }
      path="/mfgsearch"
    />
    <Route
      element={
        <App>
          <CertificatePage />
        </App>
      }
      path="/certificate/:homeId"
    />
    <Route
      element={
        <App>
          <Login />
        </App>
      }
      path="/login"
    />
    <Route
      path="*"
      element={
        <App>
          <NotFound />
        </App>
      }
    />
  </Routes>
);
