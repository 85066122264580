import Component from '../components/component.react.js'
import React from 'react'

class Footer extends Component {
  render() {
    return (
      <footer className="hidefromprinter">
        <p>&#169; Northwest Energy Works.</p>
        <p>
          Website developed by <a href="http://www.alseageo.com/">Alsea Geospatial, Inc.</a>
        </p>
      </footer>
    )
  }
}

export default Footer
