import React from 'react'
import shallowEqual from 'react-pure-render/shallowEqual.js'
import immutable from 'immutable'
import SelectRecord from './select.js'
import store from '../redux/store/index.js'

// import diff from 'immutablediff';

/**
 * Purified React.Component. Goodness.
 * http://facebook.github.io/react/docs/advanced-performance.html
 */
class Component extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    // console.log("Component shouldComponentUpdate nextProps", nextProps);
    // console.log("Component shouldComponentUpdate nextState", nextState);
    const shouldUpdate = !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState)

    // if (shouldUpdate)
    //   this._logShouldUpdateComponents(nextProps, nextState)

    return shouldUpdate
  }

  static getActiveUserRecord() {
    return store.getState().users.activeUser
  }

  // Helper function for loading selection lists from the store in a format
  // that the Formsy Select component will recognize.
  getSelectItemList(selectList) {
    // Load up the list of objects store
    let selectRecords = immutable.List();
    // Map between the store json and a selection object
    selectList.map((selectJson) => {
      // console.log("selectJson", selectJson);
      return (selectRecords = selectRecords.push(new SelectRecord(selectJson)));
    });
    // console.log("selectList", selectList);

    var SelectOptionsList = selectRecords
      .map(function (selectOptions) {
        return { value: selectOptions.value, label: selectOptions.label };
      })
      .slice(0);
    if (!!SelectOptionsList && SelectOptionsList.count() > 0) {
      // Add a selection reminder for the first element, avoids selecting the first item
      SelectOptionsList = SelectOptionsList.unshift({
        value: "",
        label: "Please select...",
      });
    }

    // console.log("SelectOptionsList", SelectOptionsList);
    return SelectOptionsList;
  }

  getSelectItemListFromRecords(recordList) {
    let selectOptionsList = []
    if (recordList) {
      for (let rec of recordList.toArray()) {
        selectOptionsList.push({ value: rec.id, label: rec.label })
      }
    }
    if (selectOptionsList.length > 0) {
      // Add a selection reminder for the first element, avoids selecting the first item
      selectOptionsList.unshift({ value: '', label: 'Please select...' })
    }
    return selectOptionsList
  }

  // // Helper to check which component was changed and why.
  // _logShouldUpdateComponents(nextProps, nextState) {
  //   const name = this.constructor.displayName || this.constructor.name
  //   console.log(`${name} shouldUpdate`)
  //   // const propsDiff = diff(this.props, nextProps).toJS()
  //   // const stateDiff = diff(this.state, nextState).toJS()
  //   // if (propsDiff.length) console.log('props', propsDiff)
  //   // if (stateDiff.length) console.log('state', stateDiff)
  // }
}

export default Component
