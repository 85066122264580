import { compose, createStore, applyMiddleware } from "redux";
import promiseMiddleware from "redux-promise";
import { createLogger } from "redux-logger";
import Immutable from "immutable";
// import { devTools } from 'redux-devtools'
import rootReducer from "../reducers/index.js";

const logger = createLogger({
  // transform logged Immutable objects into regular JS, so it is human readable
  stateTransformer: (state) => {
    const newState = {};
    for (const i of Object.keys(state)) {
      if (Immutable.Iterable.isIterable(state[i])) {
        newState[i] = state[i].toJS();
      } else {
        newState[i] = state[i];
      }
    }
    return newState;
  },
  collapsed: true,
});

const middleware = [promiseMiddleware, logger];

// Currently our Redux DevTools isn't working the greatest, so we're simply using the Redux Devtools Chrome extension
// if (__DEBUG__) {
//   createStoreWithMiddleware = compose(applyMiddleware(...middleware), devTools())(createStore)
// } else {
// createStoreWithMiddleware = compose(applyMiddleware(...middleware), window.devToolsExtension ? window.devToolsExtension() : f => f)(createStore)
// }

// This isn't working since the server is reading the js, and not seeing 'window' global
// createStoreWithMiddleware = compose(applyMiddleware(...middleware), window.devToolsExtension ? window.devToolsExtension() : f => f)(createStore)

let createStoreWithMiddleware = compose(applyMiddleware(...middleware))(
  createStore
);

function configureStore(initialState) {
  const store = createStoreWithMiddleware(rootReducer, initialState);

  // if (module.hot) {
  //   module.hot.accept('../reducers/index.js', () => {
  //     const nextRootReducer = require('../reducers/index.js')

  //     store.replaceReducer(nextRootReducer)
  //   })
  // }
  return store;
}

const store = configureStore();

export default store;

// console.log("promiseMiddleware", promiseMiddleware.default);
// console.log("typeof promiseMiddleware", typeof promiseMiddleware);

// console.log("middleware", middleware);
// console.log("typeof middleware", typeof middleware);

// let m = compose(applyMiddleware(...middleware));
// console.log("m", m);
// console.log("typeof m", typeof m);

// console.log("createStoreWithMiddleware", createStoreWithMiddleware);
// console.log("typeof createStoreWithMiddleware", createStoreWithMiddleware);

// const store = createStore(rootReducer, undefined, m);
