import Component from '../components/component.react.js'
import DocumentTitle from 'react-document-title'
import React from 'react'
import PropTypes from 'prop-types'
import requireAuth from '../auth/requireauth.react.js'
import Home from '../home/home.react.js'

class AddHome extends Component {
  render() {    
    return (
      <DocumentTitle title="Add Home">
        <div className="home-page">
          <Home manufacturerId={this.props?.location?.query.manufacturerId} {...this.props} />
        </div>
      </DocumentTitle>
    )
  }
}

AddHome.propTypes = {
  location: PropTypes.object,
}

export default requireAuth(AddHome)
