import { Record } from 'immutable'

const UserRecord = Record({
  id: '',
  userName: '',
  group: '',
  companyId: '', // represents the id of the active user's "group" type - if their group is "Manufacturer", then companyId references their ManufacturerID
  email: '',
})

export default class User extends UserRecord {
  get isAdmin() {
    return this.group === 'Admin'
  }

  get isManufacturer() {
    return this.group === 'Manufacturer'
  }
}
