import Component from "../components/component.react.js";
import requireAuth from "../auth/requireauth.react.js";
import DocumentTitle from "react-document-title";
import React from "react";
import messages from "../messages.js";
import Certificate from "../certificate/certificate.react.js";

class CertificatePage extends Component {
  render() {
    // console.log("certificate props", this.props);
    
    const homeId = window.location.pathname.split("/").pop();
    // console.log("certificate homeId", homeId);

    return (
      <DocumentTitle title={messages.en.certificate.title}>
        <div className="certificate-page">
          <p>{messages.en.certificate.infoHtml + " "}</p>
          {/* TODO Replace with dynamic homeId */}
          <Certificate homeId={homeId} {...this.props} />
        </div>
      </DocumentTitle>
    );
  }
}

export default requireAuth(CertificatePage);
