import Component from '../components/component.react.js'
import requireAuth from '../auth/requireauth.react.js'
import DocumentTitle from 'react-document-title'
import React from 'react'
import messages from '../messages.js'
import Homesearch from '../homesearch/homesearch.react.js'

class HomesearchPage extends Component {
  render() {
    return (
      <DocumentTitle title={messages.en.homesearchpage.title}>
        <div className="homesearch-page">
          <p>{messages.en.homesearchpage.infoHtml + ' '}</p>
          <Homesearch {...this.props} />
        </div>
      </DocumentTitle>
    )
  }
}

export default requireAuth(HomesearchPage)
