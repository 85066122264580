import Component from "../components/component.react.js";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import immutable from "immutable";
import { signOut } from "../redux/actions/auth.js";
import messages from "../messages.js";

import { Navbar, Nav, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const mapStateToProps = (state) => (
  // console.log("Menu state", state),
  {
  activeUser: state.users.activeUser,
});

const mapDispatchToProps = { signOut };

class Menu extends Component {
  render() {
    const userIsLoggedIn = !!this.props.activeUser;
    // console.log("Menu userIsLoggedIn", userIsLoggedIn);
    return (
      <header>
        <h1 />

        {userIsLoggedIn && (
          <Navbar>
            <Nav>
              <LinkContainer to="/addhome">
                <NavItem>Add Home</NavItem>
              </LinkContainer>
              <LinkContainer to="/searchhomes">
                <NavItem>{messages.en.menu.homesearchpage}</NavItem>
              </LinkContainer>
              <NavItem onClick={this.props.signOut}>Logout</NavItem>
            </Nav>
          </Navbar>
        )}
        {!userIsLoggedIn && (
          <Navbar>
            <Nav>
              <LinkContainer to="/login">
                <NavItem>{messages.en.menu.login}</NavItem>
              </LinkContainer>
            </Nav>
          </Navbar>
        )}
      </header>
    );
  }
}

Menu.propTypes = {
  activeUser: PropTypes.instanceOf(immutable.Record),
};


export default connect(mapStateToProps, mapDispatchToProps)(Menu);
