import { Record } from 'immutable'

const UtilityRecord = Record({
  UtilityID: '',
  UtilityName: '',
  UtilityAddress1: '',
  UtilityAddress2: '',
  UtilityCity: '',
  UtilityState: '',
  UtilityZip: '',
  UtilityPhone: '',
  UtilityContact: '',
  UtilityEmail: '',
  UtilityType: '',
  InactiveFlag: '',
  EmailFlag: '',
  CreateDate: null,
  CreateBy: null,
})

export default class Utility extends UtilityRecord {
  get id() {
    return this.UtilityID
  }
  get label() {
    return `${this.UtilityName} - ${this.UtilityState}`
  }
}
