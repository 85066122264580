import Component from "../components/component.react.js";
import requireAuth from "../auth/requireauth.react.js";
import DocumentTitle from "react-document-title";
import messages from "../messages.js";
import Home from "../home/home.react.js";

class ViewHome extends Component {
  render() {
    const homeId = window.location.pathname.split("/").pop();
    // console.log("view home homeId", homeId);

    return (
      <DocumentTitle title={messages.en.home.title}>
        <div className="home-page">
          <p>{messages.en.home.infoHtml + " "}</p>
          <Home
            homeId={homeId}
            manufacturerId={this.props.location?.query.manufacturerId}
            {...this.props}
          />
        </div>
      </DocumentTitle>
    );
  }
}

export default requireAuth(ViewHome);
// export default ViewHome
