import "./mfglist.css";
import Component from "../components/component.react.js";
import React from "react";
import Formsy from "formsy-react";
import { Row, Select } from "formsy-react-components";
import { getManufacturers } from "../manufacturer/actions.js";
// import connectToStores from "alt-utils/lib/connectToStores.js";
// import MfgListStore from "./store.js";
// import ManufacturerActions from "../manufacturer/actions.js";

class Mfglist extends Component {
  constructor(props, context) {
    super(props, context);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      manufacturers: null,
      error: null,
    };
  }

  // static getStores() {
  //   // required for alt's connectToStore
  //   return [MfgListStore];
  // }

  // static getPropsFromStores() {
  //   // required for alt's connectTo Store
  //   return MfgListStore.getState();
  // }

  componentDidMount() {
    // Populate the dropdowns from the store
    // ManufacturerActions.getManufacturers;
    const fetchManufacturers = async () => {
      try {
        const res = await getManufacturers();
        console.log("res", res);
        console.log("getManufacturers res", typeof res, res);
        this.setState({ manufacturers: res });
      } catch (err) {
        this.setState({ error: "Error fetching manufacturers" });
        console.error("Error fetching manufacturers:", err);
      }
    };
    fetchManufacturers();
  }

  submitForm(data) {
    console.log("form data", data);
    const params = null;
    const query = { manufacturerId: data.manufacturer };
    console.log("query", query);
    // this.props.history.pushState("viewhome", params, query);
    this.props.navigate(`/viewhome/${query.manufacturerId}`);
  }

  render() {
    // var Row = FRC.Row;
    // var Select = FRC.Select;

    var SharedProps = {
      layout: "horizontal",
      validatePristine: false,
      disabled: false,
    };

    const { manufacturers } = this.state;
    console.log("manufacturers", manufacturers);

    let manufacturersData;
    if (manufacturers) {
      manufacturersData = manufacturers.map((manufacturer) => ({
        value: manufacturer?.ManufacturerID,
        label: `${manufacturer?.ManufacturerName} - ${manufacturer?.ManufacturerState}`,
      }));
    }

    console.log("manufacturersData", manufacturersData);

    return (
      <div className="row">
        <div className="page-header">
          <h3>Select a manufacturer and click Go.</h3>
        </div>
        <div className="well">
          <Formsy
            className="form-horizontal"
            onValidSubmit={this.submitForm}
            ref="form"
          >
            <fieldset>
              <legend>HOME SEARCH</legend>
              <Select
                {...SharedProps}
                name="manufacturer"
                label="Manufacturer"
                labelClassName="control-label"
                className="form-control"
                options={this.getSelectItemList(manufacturersData ?? [])}
              />
            </fieldset>
            <Row layout="horizontal" />
            {/* <Row layout="horizontal"> */}
            <div className="form-group row">
              <label className="control-label col-sm-3"></label>
              <div className="col-sm-9">
                <input
                  className="btn btn-primary"
                  formNoValidate={true}
                  type="submit"
                  value="Go"
                />
              </div>
            </div>
            {/* </Row> */}
          </Formsy>
        </div>{" "}
        {/* end well */}
      </div> // end row
    );
  }
  //
  //   // const {manufacturers, editables, pendingActions} = this.props;
  //   const {manufacturers} = this.props;
  //
  //   if (!manufacturers.size)
  //     return (
  //       //<p>{msg('manufacturers.emptyList')}</p>
  //       <p>No manufacturers found</p>
  //     );
  //
  //   return (
  //     <select name="manufacturer" className="mfg-list">
  //       {manufacturers.map(manufacturer => {
  //         // const editable = editables.get(manufacturer.id);
  //         const disabled = false;
  //           // !!editable &&
  //           // pendingActions.has(actions.onEditableSave.toString());
  //         return (
  //           <Manufacturer
  //             disabled={disabled}
  //             editable={true}
  //             key={manufacturer.id}
  //             manufacturer={manufacturer}
  //           />
  //         );
  //       })}
  //     </select>
  //   );
  // }
}

// Mfglist.propTypes = {
//   manufacturers: React.PropTypes.instanceOf(immutable.List) //React.PropTypes.instanceOf(immutable.Mfglist)
// };

// Mfglist = connectToStores(Mfglist);

export default Mfglist;
