export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export function signInSuccess(user) {
  return {
    type: SIGN_IN_SUCCESS,
    user,
  }
}

export const SIGN_OUT = 'SIGN_OUT'
export const signOut = () => {
  // Signing out gets us in a weird state since we're using Component.getActiveUserRecord all over the place, so
  // for now we're just refreshing the page to sign out.
  window.location.reload(true)
  return {
    type: SIGN_OUT,
  }
}
