import Component from "../components/component.react.js";
import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import { Input, Row, Select } from "formsy-react-components";
import { Table, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import * as HomesearchActions from "../redux/actions/homeSearch.js";
import { exportHomesCSV } from "../utils/exportCSV.js";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    manufacturers: state.homeSearch.manufacturers,
    retailers: state.homeSearch.retailers,
    homes: state.homeSearch.homes,
    loading: state.homeSearch.loading,
  };
};

export class Homesearch extends Component {
  constructor() {
    super();
    this.done = this.done.bind(this);
    this.resetHomes = this.resetHomes.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.formRef = React.createRef();

    this.state = {
      redirectTo: null,
    };
  }

  componentDidMount() {
    // Populate the dropdowns from the store
    this.props.dispatch(HomesearchActions.getRetailers());
    this.props.dispatch(HomesearchActions.getManufacturers());
  }

  componentWillUnmount() {
    this.props.dispatch(HomesearchActions.resetHomes());
  }

  nameParse(LastName, FirstName) {
    var output = "";
    if (!Object.is(LastName, null) && !Object.is(FirstName, null)) {
      output = `${FirstName} ${LastName}`;
    } else if (!Object.is(FirstName, null)) {
      output = FirstName;
    } else if (!Object.is(LastName, null)) {
      output = LastName;
    }

    return output.trim();
  }

  dateParse(rawDate) {
    if (!Object.is(rawDate, null)) {
      var field_strings = rawDate.split("T");
      return field_strings[0];
    } else {
      return null;
    }
  }

  resetHomes() {
    this.props.dispatch(HomesearchActions.resetHomes());
    // console.log("form ref", this.formRef);
    if (this.formRef.current) {
      this.formRef.current.reset();
    }
  }

  submitForm(data) {
    // console.log("form data", data);
    const activeUser = Component.getActiveUserRecord();
    if (activeUser?.isManufacturer) {
      data.manufacturer_id = activeUser.companyId;
    }
    this.setState({ submittedFormData: data });
    this.props.dispatch(HomesearchActions.startLoading());
    this.props.dispatch(HomesearchActions.getHomes(data));
  }

  done() {
    // this.props?.history?.pushState("/");
    // window.location.href = "/";
    this.setState({ redirectTo: "/" });
  }

  exportCSV() {
    // console.log("In export CSV", this.state);
    const { retailer_id, manufacturer_id, from_date, to_date } =
      this.state.submittedFormData;
    let retailerName, manufacturerName;
    if (retailer_id) {
      for (const retailer of this.props.retailers.toJS()) {
        if (parseInt(retailer_id, 10) === retailer.RetailerID) {
          retailerName = retailer.RetailerName;
        }
      }
    }
    if (manufacturer_id) {
      for (const manufacturer of this.props.manufacturers.toJS()) {
        if (parseInt(manufacturer_id, 10) === manufacturer.ManufacturerID) {
          manufacturerName = manufacturer.ManuFacturerName;
        }
      }
    }
    exportHomesCSV(
      this.props.homes.toJS(),
      retailerName,
      manufacturerName,
      from_date,
      to_date
    );
  }

  render() {
    // Check if we should redirect
    if (this.state.redirectTo) {
      return <Navigate to={this.state.redirectTo} />;
    }

    // console.log("this props", this.props.retailers);

    const SharedProps = {
      layout: "horizontal",
      validatePristine: false,
    };
    const BUTTON_COLUMN_WIDTH = "172px"; // fixed width to fix Edit, Cert, and Map buttons

    const activeUser = Component.getActiveUserRecord();

    return (
      <div className="row">
        <div className="page-header">
          <h3>HOME SEARCH</h3>
        </div>
        <div className="well">
          <Formsy
            className="form-horizontal"
            onValidSubmit={this.submitForm}
            ref={this.formRef}
          >
            <fieldset>
              <Input
                {...SharedProps}
                //name="serialNumber"
                name="serial_number"
                id="serialNumber"
                value=""
                label="Serial Number"
                labelClassName="control-label"
                className="form-control"
                type="text"
                maxLength="20"
              />
              <Select
                {...SharedProps}
                //name="retailer"
                name="retailer_id"
                value=""
                label="Retailer"
                labelClassName="control-label"
                className="form-control"
                options={this.getSelectItemListFromRecords(
                  this.props.retailers
                )}
              />
              {activeUser && !activeUser?.isManufacturer ? (
                <Select
                  {...SharedProps}
                  //name="manufacturers"
                  name="manufacturer_id"
                  value=""
                  label="Manufacturers"
                  labelClassName="control-label"
                  className="form-control"
                  options={this.getSelectItemListFromRecords(
                    this.props.manufacturers
                  )}
                />
              ) : null}
              <Input
                {...SharedProps}
                //name="date[0]"
                name="from_date"
                value=""
                label="From Date"
                labelClassName="control-label"
                className="form-control"
                type="date"
                placeholder="Enter a From Date"
              />
              <Input
                {...SharedProps}
                //name="date[1]"
                name="to_date"
                value=""
                label="To Date"
                labelClassName="control-label"
                className="form-control"
                type="date"
                placeholder="Enter a To Date"
              />
              {activeUser?.isAdmin && (
                <Select
                  {...SharedProps}
                  name="filter"
                  value=""
                  label="Filter"
                  labelClassName="control-label"
                  className="form-control"
                  options={[
                    { value: "", label: "Please select..." },
                    { value: "has_coords", label: "Has coordinates" },
                    { value: "no_coords", label: "Doesn't have coordinates" },
                    {
                      value: "address_no_coords",
                      label: "Has address, but no coordinates",
                    },
                    { value: "no_address", label: "Doesn't have address" },
                  ]}
                />
              )}
            </fieldset>
            <Row layout="horizontal" />
            {/* <Row layout="horizontal"> */}
            <div className="form-group row">
              <label className="control-label col-sm-3"></label>
              <div className="col-sm-9">
                <input
                  className="btn btn-primary"
                  formNoValidate={true}
                  type="submit"
                  // defaultValue="Search"
                  value="Search"
                />{" "}
                <input
                  className="btn btn-primary"
                  onClick={this.resetHomes}
                  type="button"
                  // defaultValue="Clear"
                  value="Clear"
                />{" "}
                <input
                  className="btn btn-primary"
                  onClick={this.done}
                  formNoValidate={true}
                  type="button"
                  // defaultValue="Done"
                  value="Done"
                />
              </div>
            </div>
            {/* </Row> */}
          </Formsy>
          {!this.props?.homes.size && this.props?.loading && (
            <div
              style={{
                width: "50px",
                height: "50px",
                background: "gray",
                marginRight: "auto",
                marginLeft: "auto",
                borderRadius: "12px",
              }}
            >
              <Spinner />
            </div>
          )}
          {this.props.homes && this.props.homes.toJS().length > 0 && (
            <Table striped bordered condensed hover>
              <thead>
                <tr>
                  <th>Serial #</th>
                  <th>Cert. Date</th>
                  {activeUser?.isAdmin ? <th>Manufacturer</th> : null}
                  <th>Retailer</th>
                  <th>Owner</th>
                  <td style={{ width: BUTTON_COLUMN_WIDTH }}>
                    <a className="btn btn-primary" onClick={this.exportCSV}>
                      {" "}
                      Export CSV{" "}
                    </a>
                  </td>
                </tr>
              </thead>
              <tbody>
                {this.props?.homes.map((home) => (
                  <tr key={home.id}>
                    <td>{home.SerialNumber}</td>
                    <td>{this.dateParse(home.CertDate)}</td>
                    {activeUser?.isAdmin ? (
                      <td>{home.ManufacturerName}</td>
                    ) : null}
                    <td>{home.RetailerName}</td>
                    <td>{this.nameParse(home.OwnerLN, home.OwnerFN)}</td>
                    <td>
                      <LinkContainer to={`/viewhome/${home.id}`}>
                        <Button>Edit</Button>
                      </LinkContainer>
                      <LinkContainer to={`/certificate/${home.id}`}>
                        <Button>Cert</Button>
                      </LinkContainer>
                      {home.lat && home.lng ? (
                        <a
                          className="btn btn-default"
                          href={`https://maps.google.com/maps?q=${home.lat},${home.lng}&ll=${home.lat},${
                            home.lng
                          }&z=7`}
                          target="_blank"
                        >
                          Map
                        </a>
                      ) : (
                        <a className="btn btn-default disabled">Map</a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
        {/* end well */}
      </div> // end row
    );
  }
}

Homesearch.propTypes = {
  manufacturers: PropTypes.object,
  retailers: PropTypes.object,
  homes: PropTypes.object,
  history: PropTypes.object,
};

export default connect(mapStateToProps)(Homesearch);
