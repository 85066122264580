import "./welcome.css";
import Component from "../components/component.react.js";
import { connect } from "react-redux";
import { signOut } from "../redux/actions/auth.js";
import messages from "../messages.js";
import { Nav, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export class Welcome extends Component {
  render() {
    // TODO implement conditional menus based on login / roles
    //const userIsLoggedIn = !!this.props.activeUser;
    // console.log("welcome page props", this.props);
    return (
      <header>
        <Nav>
          <li>
            <LinkContainer to="/addhome">
              <Button>Add Home</Button>
            </LinkContainer>
          </li>
          <br />
          <li>
            <LinkContainer to="/searchhomes">
              <Button>{messages.en.menu.homesearchpage}</Button>
            </LinkContainer>
          </li>
          <br />
          <li>
            <LinkContainer to="/login">
              <Button onClick={this.props.signOut}>Logout</Button>
            </LinkContainer>
          </li>
        </Nav>
      </header>
    );
  }
}

export default connect(undefined, { signOut })(Welcome);
