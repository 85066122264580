import "./certificate.css";
import Component from "../components/component.react.js";
import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import { Form } from "formsy-react-components";
import { getCertificateTest } from "./actions.js";
import html2pdf from "html2pdf.js";
// import connectToStores from "alt-utils/lib/connectToStores.js";
// import CertificateStore from "./store.js";
// import CertificateActions from "./actions.js";
// import { Col, Grid } from "react-bootstrap";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

const certTitleStyle = {
  display: "flex",
  justifyContent: "space-between",
  margin: "10px",
};

export class Certificate extends Component {
  constructor(props, context) {
    super(props, context);
    this.goToHome = this.goToHome.bind(this);
    this.printCert = this.printCert.bind(this);
    this.formRef = React.createRef < Form > "";
    this.state = {
      certificateData: null,
      error: null,
    };
    // this.downloadPDF = this.downloadPDF.bind(this);
    // CertificateActions.getCertificateTest; //(props.homeId)
  }

  // downloadPDF() {
  //   const input = document.getElementById("pdfContent");
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, "PNG", 0, 0);
  //     pdf.save("download.pdf");
  //   });
  // }

  // static getStores() {
  //   // required for alt's connectToStore
  //   // console.log("getstores props", props)
  //   return [CertificateStore];
  // }

  // static getPropsFromStores() {
  //   // required for alt's connectTo Store
  //   // console.log("getPropsFromStores", props);
  //   CertificateStore.getImmutableState;
  // }

  // componentDidMount() {
  //   // Load the ceritficate for the selected home
  //   // TODO: getCertificate not working yet
  //   CertificateActions.getCertificateTest(this.props.homeId);
  // }

  componentDidMount() {
    const fetchCertificateData = async () => {
      try {
        const res = await getCertificateTest(this.props.homeId);
        const jsonRes = JSON.parse(res);
        this.setState({ certificateData: jsonRes });
      } catch (error) {
        this.setState({ error: "Error fetching certificate data" });
        console.error("Error fetching certificate:", error);
      }
    };
    fetchCertificateData();
  }

  getHomeIncentive(primaryHeatType, GasIncentiveAmt, ElectricIncentiveAmt) {
    let homeIncentive = "";

    if (primaryHeatType === "Gas") {
      homeIncentive = GasIncentiveAmt;
    } else if (primaryHeatType === "Electric") {
      homeIncentive = ElectricIncentiveAmt;
    }

    return this.getFormatted(homeIncentive);
  }

  addressParse(
    Name,
    Address1,
    Address2,
    City,
    State,
    Zip,
    Phone,
    breakSeparated
  ) {
    let address = "";
    if (!Object.is(Address1, null) && !Object.is(Address2, null)) {
      address = `${Address1}, ${Address2}`;
    } else if (!Object.is(Address1, null)) {
      address = Address1;
    } else if (!Object.is(Address2, null)) {
      address = Address2;
    }

    if (!breakSeparated) {
      let output = `${Name}, ${address}, ${City}, ${State} ${Zip}, ${Phone}`;
      return output.trim();
    } else {
      return (
        <span>
          {Name}
          <br />
          {address}
          <br />
          {City}, {State} {Zip}
          <br />
          {Phone}
        </span>
      );
    }
  }

  dateParse(rawDate) {
    if (!Object.is(rawDate, null)) {
      let date = new Date(rawDate);
      let output =
        date.getUTCMonth() +
        1 +
        "/" +
        date.getUTCDate() +
        "/" +
        date.getUTCFullYear();
      //let field_strings = rawDate.split('T');
      //return field_strings[0];
      return output;
    } else {
      return null;
    }
  }

  getFormatted(input) {
    let filler = "____________";
    let output = input;
    if (!Object.is(input, null)) {
      if (input === "") {
        output = filler;
      }
      if (input?.toLowerCase() === "unknown") {
        output = filler;
      }
      if (input?.toLowerCase() === "na") {
        output = filler;
      }
    }
    return output;
  }

  renderBrandLogo(BrandImage) {
    // let img = ''
    // if (BrandID === '1') {
    //   img = <img src="/assets/img/supergoodcents.jpg" width="172" height="91" alt="Super Good Cents logo" />
    // } else if (BrandID === '2' || BrandID === '6') {
    //   img = <img src="/assets/img/EStarL.jpeg" width="117" height="120" alt="Energy Star logo" />
    // } else if (BrandID === '5') {
    //   img = <img src="/assets/img/earthadvantage.jpg" width="208" height="61" alt="Earth Advantage logo" />
    // } else if (BrandID === '7') {
    //   img = <img src="/assets/img/neem_logo.jpg" width="151" height="130" alt="NEEM Certified logo" />
    // } else if (BrandID === '8') {
    //   img = <img src="/assets/img/eco-star.png" style={{ width: '100%' }} alt="ECO-Rated and Energy Star logo" />
    // }
    // console.log("brand image === ", BrandImage);
    return (
      <div style={{ width: "250px" }}>
        <img
          src={BrandImage}
          alt="brand image"
          style={{ maxWidth: "100%", maxHeight: "130px" }}
        />
      </div>
    );
  }

  renderHomeTypeImage(HomeTypeLogo) {
    // let img = ''
    // if (HomeTypeID === '1') {
    //   img = (
    //     <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
    //       <img src="/assets/img/NEEM_logo-new.jpg" style={{ width: '100%' }} alt="NEEM logo" />
    //     </div>
    //   )
    // } else if (HomeTypeID === '2') {
    //   img = <img src="/assets/img/NEEMplus_logo-new.jpg" style={{ width: '100%' }} alt="NEEM Plus logo" />
    // }
    // console.log("home type logo === ", HomeTypeLogo);
    return (
      <div style={{ width: "250px" }}>
        <img
          src={HomeTypeLogo}
          alt="home type logo"
          style={{ maxWidth: "100%", maxHeight: "130px" }}
        />
      </div>
    );
  }

  nameParse(LastName, FirstName) {
    var output = "";
    if (!Object.is(LastName, null) && !Object.is(FirstName, null)) {
      output = `${FirstName} ${LastName}`;
    } else if (!Object.is(FirstName, null)) {
      output = FirstName;
    } else if (!Object.is(LastName, null)) {
      output = LastName;
    }

    return output.trim();
  }

  homeownerParser(record, name) {
    let filler = `${name}____________`;
    let output = filler;

    //  If the value isn't null return a string representing it
    if (!!record) {
      output = record;

      if (record.toLowerCase() === "na") {
        output = filler;
      }

      if (record === null) {
        output = filler;
      }
    }

    return output;
  }

  goToHome() {
    this.props.history.pushState("/");
  }

  printCert() {
    const element = document.getElementById("certBodyCont");
    const opt = {
      margin: [5, 5, 5, 5],
      filename: "certificate.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 2,
        useCORS: true,
        allowTaint: false,
        logging: false,
      },
      jsPDF: {
        format: "a4",
        orientation: "portrait",
      },
    };

    html2pdf().set(opt).from(element).save();
  }

  render() {
    // console.log("props homeId== ", this.props.homeId);

    const { certificateData, error } = this.state;
    // console.log("certificateData", certificateData);

    let cert_number =
      certificateData?.ManufacturerID + certificateData?.SerialNumber;

    let maxGlazingPercent = certificateData?.MaxGlazing * 100;

    let retailer_address = this.addressParse(
      certificateData?.RetailerName,
      certificateData?.RetailerAddress1,
      certificateData?.RetailerAddress2,
      certificateData?.RetailerCity,
      certificateData?.RetailerState,
      certificateData?.RetailerZip,
      certificateData?.RetailerPhone
    );

    // let retailer_address_with_breaks = this.addressParse(
    //   certificateData?.RetailerName,
    //   certificateData?.RetailerAddress1,
    //   certificateData?.RetailerAddress2,
    //   certificateData?.RetailerCity,
    //   certificateData?.RetailerState,
    //   certificateData?.RetailerZip,
    //   certificateData?.RetailerPhone,
    //   true
    // );

    let manufacturer_address = this.addressParse(
      certificateData?.ManufacturerName,
      certificateData?.ManufacturerAddress1,
      certificateData?.ManufacturerAddress2,
      certificateData?.ManufacturerCity,
      certificateData?.ManufacturerState,
      certificateData?.ManufacturerZip,
      certificateData?.ManufacturerPhone
    );

    // let manufacturer_address_with_breaks = this.addressParse(
    //   certificateData?.ManufacturerName,
    //   certificateData?.ManufacturerAddress1,
    //   certificateData?.ManufacturerAddress2,
    //   certificateData?.ManufacturerCity,
    //   certificateData?.ManufacturerState,
    //   certificateData?.ManufacturerZip,
    //   certificateData?.ManufacturerPhone,
    //   true
    // );

    // let utility_address = this.addressParse(primaryUtil.UtilityName,
    //                                         primaryUtil.UtilityAddress1,
    //                                         primaryUtil.UtilityAddress2,
    //                                         primaryUtil.UtilityCity,
    //                                         primaryUtil.UtilityState,
    //                                         primaryUtil.UtilityZip,
    //                                         primaryUtil.UtilityPhone);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "950px",
          margin: "0px auto",
        }}
      >
        <div id="certBodyCont">
          <div className="certBody">
            <div style={certTitleStyle}>
              {this.renderBrandLogo(certificateData?.BrandLogo)}
              <div>
                <h4>Northwest Energy-Efficient</h4>
                <h4>Manufactured Housing Program</h4>
                <p>&nbsp;</p>
                <h4 style={{ marginBottom: 0 }}>Certificate of Compliance</h4>
              </div>
              {this.renderHomeTypeImage(certificateData?.HomeTypeLogo)}
            </div>
            <div className="borderLine">&nbsp;</div>
            <div className="certSection">
              <p>
                <b>
                  <span className="alignLeft">
                    NEEM Certificate Number: {cert_number}{" "}
                  </span>
                </b>
                <b>
                  <span className="alignRight">
                    {" "}
                    Date Entered: {this.dateParse(
                      certificateData?.CertDate
                    )}{" "}
                  </span>
                </b>
              </p>
            </div>
            <div className="borderLine">&nbsp;</div>
            <div className="certSection">
              <p>
                <b>Homeowner:</b>
              </p>
              <p>
                {this.homeownerParser(
                  this.nameParse(
                    certificateData?.OwnerLN,
                    certificateData?.OwnerFN
                  ),
                  "Name"
                )}
              </p>
              <p>
                {this.homeownerParser(certificateData?.SiteAddress, "Address")}
              </p>
              <p>
                {this.homeownerParser(certificateData?.SiteCity, "City")},{" "}
                {this.homeownerParser(certificateData?.SiteState, "State")}{" "}
                {this.homeownerParser(certificateData?.SiteZip, "Zip")}
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Retailer:</b> {retailer_address}
              </p>
              <p>
                <b>Manufacturer:</b> {manufacturer_address}
              </p>
            </div>
            <div className="borderLine">&nbsp;</div>
            <div className="certSection">
              <p className="subHeading">
                <b>Manufactured Home</b>
              </p>
              <p>
                <span className="alignLeftCenter">
                  <b>Serial #:</b> {certificateData?.SerialNumber}{" "}
                </span>
                <span className="alignRightCenter">
                  <b>Model #:</b> {certificateData?.ModelNumber}{" "}
                </span>
              </p>
              <p className="thinSpace">&nbsp;</p>
              <p>
                <b>Home Type:</b> {certificateData?.HomeType} -{" "}
                {certificateData?.BrandDesc}
              </p>
              <p>
                <b>Primary Heating System:</b> {certificateData?.HeatingSystem}
              </p>
              <p className="oneLine">
                <b>Energy-Efficient Path:</b>
              </p>
              <p className="oneLine">
                {certificateData?.PathDetail}, Window Class{" "}
                {certificateData?.WindowsClass}, Glazing {maxGlazingPercent}%
              </p>
            </div>
            <div className="thinSpace">&nbsp;</div>
            <div className="borderLine">&nbsp;</div>
            <div className="certSection">
              <p className="oneLine">
                <b>Qualification Criteria:</b>
              </p>
              <p className="oneLine">
                This home qualifies for certification by the Northwest
                Energy-Efficient Manufactured Housing (NEEM) Program as meeting{" "}
                {certificateData?.HomeType} specifications. A record of
                certification is on file at Northwest Energy Works, NEEM Program
                administrator.
              </p>
            </div>
            <div className="thinSpace">&nbsp;</div>
            <div className="borderLine">&nbsp;</div>
            <div className="certSection">
              <p>
                {certificateData?.ManufacturerName} certifies that this home has
                been built in accordance with Northwest Energy-Efficient
                Manufactured Housing Program specifications.
              </p>
              <p>&nbsp;</p>
              <p>
                Manufacturer:_________________________________________________________
                Date:__________________
              </p>
              <p>&nbsp;</p>
              <p className="certFooter">
                Keep this document as Record of Proof
              </p>
            </div>
          </div>
          {/* <div className="html2pdf__page-break"></div> */}
          {/* <div className="certBody page-break">
            <div style={certTitleStyle}>
              {this.renderBrandLogo(certificateData?.BrandLogo)}
              <div>
                <h4>Northwest Energy-Efficient</h4>
                <h4>Manufactured Housing Program</h4>
                <p>&nbsp;</p>
                <h4>Utility Incentive Application</h4>
              </div>
              {this.renderHomeTypeImage(certificateData?.HomeTypeLogo)}
            </div>
            <div className="borderLine">&nbsp;</div>
            <div className="certSection certSectionCol">
              <Grid>
                <Row>
                  <Col xs={6} className="column">
                    <p>
                      <b>Manufacturer:</b>
                      <br />
                      {manufacturer_address_with_breaks}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p>
                      <b>Retailer:</b>
                      <br />
                      {retailer_address_with_breaks}
                    </p>
                  </Col>
                </Row>
              </Grid>
            </div>
            <div className="borderLine">&nbsp;</div>
            <div className="certSection">
              <p>
                <b>Homeowner and Home Siting:</b>
              </p>
              <p className="oneLine">
                {this.homeownerParser(certificateData?.OwnerFN, "First Name") +
                  " "}
                {this.homeownerParser(certificateData?.OwnerLN, "Last Name")}
              </p>
              <p className="oneLine">
                {this.homeownerParser(certificateData?.SiteAddress, "Address")}
              </p>
              <p className="oneLine">
                {this.homeownerParser(certificateData?.SiteCity, "City")},{" "}
                {this.homeownerParser(certificateData?.SiteState, "State")}{" "}
                {this.homeownerParser(certificateData?.SiteZip, "Zip")}
              </p>
              <p className="oneLine">
                {this.homeownerParser(
                  certificateData?.SitePhone,
                  "Phone Number"
                )}
              </p>
              <p style={{ marginTop: "14px" }} className="lineHeight">
                <b>Home Siting Date:</b>__________________
              </p>
              <div className="certHalfPage certSectionCol">
                <Grid>
                  <Row>
                    <Col xs={7} className="column">
                      <p className="lineHeight">
                        <b>Home Serial #:</b> {certificateData?.SerialNumber}
                      </p>
                    </Col>
                    <Col xs={5}>
                      <p className="lineHeight">
                        <b>Date Entered:</b>{" "}
                        {this.dateParse(certificateData?.CertDate)}
                      </p>
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <Row>
                    <Col xs={7} className="column">
                      <p className="lineHeight">
                        <b>Home Type:</b> {certificateData?.HomeType} -{" "}
                        {certificateData?.BrandDesc}
                      </p>
                    </Col>
                    <Col xs={5}>
                      <p>&nbsp;</p>
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <Row>
                    <Col xs={7} className="column">
                      <p className="lineHeight">
                        <b>Home Incentive:</b>
                        {this.getHomeIncentive(
                          certificateData?.HeatingSource,
                          certificateData?.GasIncentiveAmt,
                          certificateData?.ElectricIncentiveAmt
                        )}
                      </p>
                    </Col>
                    <Col xs={5}>
                      <p className="lineHeight">
                        <b>Utility Acct. #: </b> __________________________
                      </p>
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <Row>
                    <Col xs={7} className="column">
                      <p className="lineHeight">
                        <b>Electric Utility:</b> _________________________
                      </p>
                    </Col>
                    <Col xs={5}>
                      <p className="lineHeight">
                        <b>Natural Gas Utility:</b> ______________________
                      </p>
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <Row>
                    <Col xs={7} className="column">
                      <p className="lineHeight">
                        <b>Water Heater:</b>{" "}
                        {this.getFormatted(certificateData?.EnergySource)}{" "}
                      </p>
                    </Col>
                    <Col xs={5}>
                      <p className="lineHeight">
                        <b>Primary Heating System:</b>{" "}
                        {this.getFormatted(certificateData?.HeatingSystem)}
                      </p>
                    </Col>
                  </Row>
                </Grid>
              </div>
            </div>
            <div className="borderLine">&nbsp;</div>
            <div className="certSection">
              <p>Homeowner Instructions for Utility Application form:</p>
            </div>
            <div className="borderLine">&nbsp;</div>
            <div className="certSection">
              <p>
                1. Enter the date the home was sited on the foundation
                (Homeowner and Home Siting section).
              </p>
              <p>
                2. Complete, sign, and date (make any necessary changes to the
                homeowner information or home siting address).
              </p>
              <p>
                3. Confirm or correct the utility listed. Include your utility
                acct. number.
              </p>
              <p className="thinSpace">&nbsp;</p>
              <p>
                I certify, I am the original owner of the manufactured home
                sited at the address listed, and, to the best of my knowledge,
                all information regarding this home is accurate.
              </p>
              <p className="thinSpace">&nbsp;</p>
              <p>
                Homeowner
                Signature:_________________________________________________
                Date:_________________
              </p>
              <p className="thinSpace">&nbsp;</p>
              <p className="centerText">
                <b>
                  Not all utilities have customer incentive programs in place.
                  Payment of incentives is at the discretion of the utility or
                  its representative.
                </b>
              </p>
              <p>
                Mail to: &nbsp;
                {certificateData?.UtilityID !== 1000001
                  ? `${this.getFormatted(certificateData?.UtilityName)}
                  \r\nAttn: Utility Incentive Administrator ${this.homeownerParser(
                    certificateData?.UtilityContact,
                    "Contact"
                  )}
                  \r\n${this.homeownerParser(
                    certificateData?.UtilityAddress1,
                    "Address"
                  )}
                  ${
                    certificateData?.UtilityAddress2 != null
                      ? certificateData?.UtilityAddress2
                      : ""
                  }
                  \r\n${this.homeownerParser(certificateData?.UtilityCity, "City")}
                  ${this.homeownerParser(certificateData?.UtilityState, "State")}
                  ${this.homeownerParser(certificateData?.UtilityZip, "Zip Code")}`
                  : "If utility is unknown, please contact your NEEM retailer."}{" "}
              </p>
              <p className="thinSpace">&nbsp;</p>
              <p className="endPrintPage">
                This document serves as Proof of Certification from the
                Northwest Energy-Efficient Manufactured (NEEM) Home Program
              </p>
              <div className="hidefromprinter">
                <div className="certSection">
                  <div>
                    <p>
                      1. Click Print to print the{" "}
                      <b>Certificate of Compliance</b> and the{" "}
                      <b>Utility Incentive Application</b> for the owner.
                    </p>
                    <p>
                      2. When finished, click the Done button to return to the
                      Main Menu.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="well">
          <Formsy onSubmit={this.goToHome} ref={this.formRef}>
            <div className="certFormBtns">
              <span>
                <input
                  className="btn btn-primary"
                  type="button"
                  defaultValue="Download PDF"
                  onClick={this.printCert}
                />
              </span>{" "}
              {/* <span>
                <input
                  className="btn btn-primary"
                  type="submit"
                  defaultValue="Done"
                />
              </span> */}
            </div>
          </Formsy>
        </div>
      </div>
    );
  }
}

Certificate.propTypes = {
  homeId: PropTypes.string,
};

// Certificate = connectToStores(Certificate);

export default Certificate;
