// import alt from "../alt.js";
import NeemWebAPIUtils from "../utils/NeemWebAPIUtils.js";

// class ManufacturerActions {
//   getManufacturers() {
//     console.log("Action called")
//     var _this = this
//     NeemWebAPIUtils.getManufacturers().then(
//       console.log("in success of getManufacturers"),
//       function (response, textStatus) {
//         if (textStatus === "success") {
//           _this.dispatch(response);
//         }
//       },
//       function () {
//         // Failed
//         console.log("failed to get manufaturers");
//       }
//     ).catch((err)=>{console.log("err in getManufacturers");});
//   }
// }

// export default alt.createActions(new ManufacturerActions)

export async function getManufacturers() {
  return NeemWebAPIUtils.getManufacturers()
    .then(function (response, textStatus) {
      console.log("textStatus", textStatus);
      console.log("response", response);

      if (textStatus === "success") {
        return response;
      }
    })
    .catch((err) => {
      console.error("err in getManufacturers", err);
    });
}
