import Component from "../components/component.react.js";
import DocumentTitle from "react-document-title";
import React from "react";
import messages from "../messages.js";
import Mfglist from "../mfglist/mfglist.react.js";
import requireAuth from "../auth/requireauth.react.js";
import { useNavigate } from "react-router-dom";

class Mfgsearch extends Component {
  // static willTransitionTo(transition) {
  //   const activeUser = Component.getActiveUserRecord()
  //   console.log("transition", transition);
  //   console.log("transition activeUser", activeUser)
  //   if (!activeUser) {
  //     console.log("transition redirect")
  //     transition.redirect('login')
  //   } else {
  //     if (activeUser.isManufacturer) {
  //       const manufacturerId = activeUser.companyId
  //       if (!manufacturerId) {
  //         return
  //       }
  //       const query = { manufacturerId: manufacturerId }
  //       transition.redirect('viewhome', null, query)
  //     }
  //   }
  // }

  componentDidMount() {
    const activeUser = Component.getActiveUserRecord();
    const navigate = this.props.navigate;

    if (!activeUser) {
      navigate("/login", { replace: true });
    } else if (activeUser.isManufacturer) {
      const manufacturerId = activeUser.companyId;
      if (manufacturerId) {
        const query = { manufacturerId: manufacturerId };
        navigate(`/viewhome/${query.manufacturerId}`, { replace: true });
      }
    }
  }

  render() {
    return (
      <DocumentTitle title={messages.en.mfgsearch.title}>
        <div className="mfgsearch-page">
          <p>{messages.en.mfgsearch.infoHtml + " "}</p>
          <Mfglist navigate={this.props.navigate} />
        </div>
      </DocumentTitle>
    );
  }
}

// export default requireAuth(Mfgsearch)
function MfgsearchWithRouter(props) {
  const navigate = useNavigate();
  return <Mfgsearch {...props} navigate={navigate} />;
}

export default requireAuth(MfgsearchWithRouter);
