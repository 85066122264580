import { Record } from 'immutable'

const RetailerRecord = Record({
  RetailerID: '',
  RetailerName: '',
  RetailerAddress1: '',
  RetailerAddress2: '',
  RetailerCity: '',
  RetailerState: '',
  RetailerZip: '',
})

export default class Retailer extends RetailerRecord {
  get id() {
    return this.RetailerID
  }
  get label() {
    return `${this.RetailerName} - ${this.RetailerCity}, ${this.RetailerState}`
  }
}
