import Immutable from 'immutable'
import * as types from '../actions/home.js'
import HomeRecord from '../../home/home.js'
import ManufacturerRecord from '../../manufacturer/manufacturer.js'
import UtilityRecord from '../../utility/utility.js'
import _ from 'lodash'

export const HomeState = Immutable.Record({
  // eslint-disable-line new-cap
  manufacturerRetailers: Immutable.List([]),
  homeTypes: Immutable.List([]),
  heatingSystem: Immutable.List([]),
  energySource: Immutable.List([]),
  homeStatus: Immutable.List([]),
  path: Immutable.List([]),
  manufacturerPath: Immutable.List([]),
  filteredPath: Immutable.List([]),
  states: Immutable.List([
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'KS', label: 'Kansas' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'WA', label: 'Washington' },
    { value: 'WY', label: 'Wyoming' },
    { value: 'CN', label: 'Canada' },
  ]),
  brands: Immutable.List([]),
  home: Immutable.Map(),
  manufacturer: Immutable.Map(),
  manufacturers: Immutable.List([]),
  utilities: Immutable.List([]),
  // form-related
  addressEdited: false,
  latLngEdited: false,
})

const initialState = new HomeState()

const handlers = {
  [types.GET_MANUFACTURER_RETAILERS]: (state, action) => {
    const manufacturerRetailerList = _.chain(action.payload)
      .map(manufacturerRetailer => {
        return {
          value: manufacturerRetailer.RetailerID?.toString(),
          label:
            manufacturerRetailer.RetailerName +
            ' - ' +
            manufacturerRetailer.RetailerCity +
            ', ' +
            manufacturerRetailer.RetailerState,
        }
      })
      .value()
    return state.set('manufacturerRetailers', Immutable.List(manufacturerRetailerList))
  },
  [types.GET_MANUFACTURER_BY_ID]: (state, action) => {
    return state.set('manufacturer', new ManufacturerRecord(action.payload))
  },
  [types.GET_MANUFACTURERS_HOME]: (state, action) => {
    const manufacturers = action.payload;
    return state.set('manufacturers', Immutable.List(manufacturers.map(man => new ManufacturerRecord(man))))
  },
  [types.GET_UTILITIES_HOME]: (state, action) => {
    return state.set('utilities', Immutable.List(action.payload.map(ut => new UtilityRecord(ut))))
  },
  [types.GET_HOME_TYPE]: (state, action) => {
    const homeTypeList = _.chain(action.payload)
      .map(select => {
        return { value: select.HomeTypeID?.toString(), label: select.HomeType }
      })
      .value()
    return state.set('homeTypes', Immutable.List(homeTypeList))
  },
  [types.GET_HEATING_SYSTEM]: (state, action) => {
    const heatingSystemList = _.chain(action.payload)
      .map(select => {
        return { value: select.HeatingSystemID?.toString(), label: select.HeatingSystem }
      })
      .value()
    return state.set('heatingSystem', Immutable.List(heatingSystemList))
  },
  [types.GET_ENERGY_SOURCE]: (state, action) => {
    const energySourceList = _.chain(action.payload)
      .map(select => {
        return { value: select.EnergySourceID?.toString(), label: select.EnergySource }
      })
      .value()
    return state.set('energySource', Immutable.List(energySourceList))
  },
  [types.GET_HOME_STATUS]: (state, action) => {
    const homeStatusList = _.chain(action.payload)
      .map(select => {
        return { value: select.HomeStatusID?.toString(), label: select.HomeStatus }
      })
      .value()
    return state.set('homeStatus', Immutable.List(homeStatusList))
  },
  [types.GET_PATH]: (state, action) => {
    const pathList = _.chain(action.payload)
      .map(select => {
        return { value: select.PathID?.toString(), label: select.PathDescription }
      })
      .value()
    return state.set('path', Immutable.List(pathList))
  },
  [types.GET_MANUFACTURER_PATH]: (state, action) => {
    const manufacturerPathList = _.chain(action.payload)
      .map(select => {
        return { value: select.PathID?.toString(), label: select.PathDescription }
      })
      .value()
    return state.set('manufacturerPath', Immutable.List(manufacturerPathList))
  },
  [types.GET_FILTERED_PATH]: (state, action) => {
    const filteredPathList = _.chain(action.payload)
      .map(select => {
        return { value: select.PathID?.toString(), label: select.PathDescription }
      })
      .value()
    return state.set('filteredPath', Immutable.List(filteredPathList))
  },
  [types.GET_BRAND]: (state, action) => {
    const brandList = _.chain(action.payload)
      .map(select => {
        return { value: select.BrandID?.toString(), label: select.BrandDesc }
      })
      .value()
    return state.set('brands', Immutable.List(brandList))
  },
  [types.GET_HOME]: (state, action) => {
    const homeObject = action.payload
    return state.set(
      'home',
      new HomeRecord({
        lat: propHelper(homeObject.lat),
        lng: propHelper(homeObject.lng),
        HomeID: propHelper(homeObject.HomeID),
        ManufacturerID: propHelper(homeObject.ManufacturerID),
        RetailerID: propHelper(homeObject.RetailerID),
        HomeTypeID: propHelper(homeObject.HomeTypeID),
        HeatingSystemID: propHelper(homeObject.HeatingSystemID),
        PathID: propHelper(homeObject.PathID),
        BrandID: propHelper(homeObject.BrandID),
        HomeStatusID: propHelper(homeObject.HomeStatusID),
        HomeSqFt: propHelper(homeObject.HomeSqFt),
        GlassDoorSqFt: propHelper(homeObject.GlassDoorSqFt),
        SkylightSqFt: propHelper(homeObject.SkylightSqFt),
        WindowSqFt: propHelper(homeObject.WindowSqFt),
        ElecUtilityID: propHelper(homeObject.ElecUtilityID),
        GasUtilityId: propHelper(homeObject.GasUtilityId),
        EnergySourceID: propHelper(homeObject.EnergySourceID),
        ModelNumber: propHelper(homeObject.ModelNumber),
        SerialNumber: propHelper(homeObject.SerialNumber),
        Sections: propHelper(homeObject.Sections),
        CertDate: propHelper(homeObject.CertDate),
        SiteAddress: propHelper(homeObject.SiteAddress),
        SiteCity: propHelper(homeObject.SiteCity),
        SiteState: propHelper(homeObject.SiteState),
        SiteZip: propHelper(homeObject.SiteZip),
        SitePhone: propHelper(homeObject.SitePhone),
        SiteEmail: propHelper(homeObject.SiteEmail),
        OwnerFN: propHelper(homeObject.OwnerFN),
        OwnerLN: propHelper(homeObject.OwnerLN),
      })
    )
  },
  [types.LAT_LNG_EDITED]: state => state.set('latLngEdited', true),
  [types.ADDRESS_EDITED]: state => state.set('addressEdited', true),
  [types.RESET_HOME_STORE]: () => initialState,
}

function propHelper(inProp) {
  var rtn = null

  //  If the value isn't null return a string representing it
  if (inProp !== null && inProp !== undefined) {
    rtn = inProp.toString()
  }

  return rtn
}

export default function homeReducer(state = initialState, action) {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  } else {
    return state
  }
}
