import Immutable from 'immutable'
import * as types from '../actions/auth.js'
import UserRecord from '../../users/user.js'

export const UserState = Immutable.Record({
  // eslint-disable-line new-cap
  activeUser: null,
})

const initialState = new UserState()

const handlers = {
  [types.SIGN_IN_SUCCESS]: (state, action) => {
    return state.set(
      'activeUser',
      new UserRecord({
        id: action.user.UserID,
        userName: action.user.LogonName,
        group: action.user.GroupName,
        companyId: action.user.CompanyID,
        email: action.user.Email,
      })
    )
  },
  [types.SIGN_OUT]: () => initialState,
}

export default function usersReducer(state = initialState, action) {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  } else {
    return state
  }
}
