import "./app.css";
import Component from "../components/component.react.js";
import Footer from "./footer.react.js";
import Menu from "./menu.react.js";
import React from "react";

// have both outer columns the same width so that the inner column is in center of page
const outerColumnWidth = "140";

class App extends Component {
  render() {
    // console.log("App props", this.props);
    // console.log("App state", this.state);

    return (
      <div className="page">
        <table width="100%" className="hidefromprinter">
          <tbody>
            <tr>
              <td width={outerColumnWidth}>
                <img
                  src="/assets/img/NEEM_logo-fullname.jpg"
                  width="140"
                  alt="NEEM Certified"
                />
              </td>
              <td>
                <p>Northwest Energy-Efficient Manufactured Housing Program</p>{" "}
                <p>Home Certification and Tracking System</p>
              </td>
              <td width={outerColumnWidth} style={{ textAlign: "right" }}>
                <img
                  src="/assets/img/Energy_Star_logo.svg"
                  width="65"
                  height="66"
                  alt="Energy Star"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Menu />
        {/* Children are given from Router component - renders the current route's component */}
        {/* We have to clone the children, as laid out in the 1.0 migration guide - https://github.com/reactjs/react-router/blob/master/upgrade-guides/v1.0.0.md#routehandler */}
        {this.props.children &&
          React.cloneElement(this.props.children, { ...this.props })}
        <Footer />
      </div>
    );
  }
}

export default App;
