import Component from "../components/component.react.js";
import DocumentTitle from "react-document-title";
import messages from "../messages.js";
import { Link } from "react-router-dom"
// import { Link, Navigate } from 'react-router'
// import { Nav } from 'react-bootstrap'

class NotFound extends Component {
  render() {
    return (
      <DocumentTitle title={messages.en.notFound.title}>
        <div className="notfound-page">
          <h1>{messages.en.notFound.header}</h1>
          <p>{messages.en.notFound.message}</p>
          <Link to="/login">{messages.en.notFound.continueMessage}</Link>
          {/* <Navigate to="/">{messages.en.notFound.continueMessage}</Navigate> */}
          {/* <Nav.Link href='/'>{messages.en.notFound.continueMessage}</Nav.Link> */}
        </div>
      </DocumentTitle>
    );
  }
}

export default NotFound;
