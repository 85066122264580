import Component from '../components/component.react.js'
import requireAuth from '../auth/requireauth.react.js'
import DocumentTitle from 'react-document-title'
import messages from '../messages.js'
import Welcome from '../welcome/welcome.react.js'

class WelcomePage extends Component {
  render() {
    // console.log("welcome props", this.props)
    return (
      <DocumentTitle title={messages.en.welcomepage.title}>
        <div className="welcome-page">
          <p>{messages.en.welcomepage.infoHtml + ' '}</p>
          <Welcome />
        </div>
      </DocumentTitle>
    )
  }
}

export default requireAuth(WelcomePage)
