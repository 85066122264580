import NeemWebAPIUtils from '../../utils/NeemWebAPIUtils.js'

export const GET_IS_UNIQUE_SERIAL = 'GET_IS_UNIQUE_SERIAL'
export function getIsUniqueSerial(ManufacturerID, SerialNumber, successCallback) {
  return {
    type: GET_IS_UNIQUE_SERIAL,
    payload: NeemWebAPIUtils.getIsUniqueSerial(ManufacturerID, SerialNumber).then(function(response, textStatus) {
      const success = response
      // TODO: calling this callback (instead of returning it) is hacky
      successCallback(success)
      return success
    }),
  }
}

export const GET_MANUFACTURER_RETAILERS = 'GET_MANUFACTURER_RETAILERS'
export function getManufacturerRetailers(manufacturerId) {
  return {
    type: GET_MANUFACTURER_RETAILERS,
    payload: NeemWebAPIUtils.getManufacturerRetailers(manufacturerId),
  }
}

// get the ManufacturerRecord associated to the home (existing or new home)
export const GET_MANUFACTURER_BY_ID = 'GET_MANUFACTURER_BY_ID'
export function getManufacturerById(id) {
  return {
    type: GET_MANUFACTURER_BY_ID,
    payload: NeemWebAPIUtils.getManufacturerById(id),
  }
}

export const GET_HOME_TYPE = 'GET_HOME_TYPE'
export function getHomeType() {
  return {
    type: GET_HOME_TYPE,
    payload: NeemWebAPIUtils.getHomeType(),
  }
}

export const GET_HEATING_SYSTEM = 'GET_HEATING_SYSTEM'
export function getHeatingSystem() {
  return {
    type: GET_HEATING_SYSTEM,
    payload: NeemWebAPIUtils.getHeatingSystem(),
  }
}

export const GET_ENERGY_SOURCE = 'GET_ENERGY_SOURCE'
export function getEnergySource() {
  return {
    type: GET_ENERGY_SOURCE,
    payload: NeemWebAPIUtils.getEnergySource(),
  }
}

export const GET_HOME_STATUS = 'GET_HOME_STATUS'
export function getHomeStatus() {
  return {
    type: GET_HOME_STATUS,
    payload: NeemWebAPIUtils.getHomeStatus(),
  }
}

export const GET_MANUFACTURERS_HOME = 'GET_MANUFACTURERS_HOME'
export function getManufacturers() {
  return {
    type: GET_MANUFACTURERS_HOME,
    payload: NeemWebAPIUtils.getManufacturers(),
  }
}

export const GET_UTILITIES_HOME = 'GET_UTILITIES_HOME'
export function getUtilities() {
  return {
    type: GET_UTILITIES_HOME,
    payload: NeemWebAPIUtils.getUtilities(),
  }
}

export const GET_PATH = 'GET_PATH'
export function getPath() {
  return {
    type: GET_PATH,
    payload: NeemWebAPIUtils.getPath(),
  }
}

export const GET_MANUFACTURER_PATH = 'GET_MANUFACTURER_PATH'
export function getManufacturerPath(manufacturerId) {
  return {
    type: GET_MANUFACTURER_PATH,
    payload: NeemWebAPIUtils.getManufacturerPath(manufacturerId),
  }
}

export const GET_FILTERED_PATH = 'GET_FILTERED_PATH'
export function getFilteredPath(options) {
  return {
    type: GET_FILTERED_PATH,
    payload: NeemWebAPIUtils.getFilteredPath(options),
  }
}

export const GET_STATES = 'GET_STATES'
export function getStates() {
  return {
    type: GET_STATES,
    payload: NeemWebAPIUtils.getStates(),
  }
}

export const GET_BRAND = 'GET_BRAND'
export function getBrand() {
  return {
    type: GET_BRAND,
    payload: NeemWebAPIUtils.getBrand(),
  }
}

export const GET_HOME = 'GET_HOME'
export function getHome(manufacturerId) {
  return {
    type: GET_HOME,
    payload: NeemWebAPIUtils.getHome(manufacturerId),
  }
}

export const UPDATE_HOME = 'UPDATE_HOME'
export function updateHome(homeId, homeJson, geocode, successCb) {
  return {
    type: UPDATE_HOME,
    payload: NeemWebAPIUtils.updateHome(homeId, homeJson, geocode).then((response, textStatus) => {
      if (textStatus === 'success') {
        if (successCb) {
          successCb()
        }
        return response
      }
    }),
  }
}

export const ADD_HOME = 'ADD_HOME'
export function addHome(homeJson, successCb) {
  return {
    type: ADD_HOME,
    payload: NeemWebAPIUtils.addHome(homeJson).then((response, textStatus) => {
      const newHomeId = response
      if (textStatus === 'success') {
        if (successCb) {
          successCb(newHomeId)
        }
        return response
      }
    }),
  }
}

export const DELETE_HOME = 'DELETE_HOME'
export function deleteHome(manufacturerId) {
  return {
    type: DELETE_HOME,
    payload: NeemWebAPIUtils.deleteHome(manufacturerId),
  }
}

export const ADDRESS_EDITED = 'ADDRESS_EDITED'
export function addressEdited() {
  return {
    type: ADDRESS_EDITED,
  }
}

export const LAT_LNG_EDITED = 'LAT_LNG_EDITED'
export function latLngEdited() {
  return {
    type: LAT_LNG_EDITED,
  }
}

export const RESET_HOME_STORE = 'RESET_HOME_STORE'
export function resetHomeStore() {
  return {
    type: RESET_HOME_STORE,
  }
}
