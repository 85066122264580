// import alt from '../alt.js'
// import NeemWebAPIUtils from '../utils/NeemWebAPIUtils.js'

// class CertificateActions {
//   getElecUtility(utilId) {
//     var _this = this
//     NeemWebAPIUtils.getUtility(utilId).then(
//       function(response, textStatus) {
//         if (textStatus === 'success') {
//           _this.dispatch(response)
//         }
//       },
//       function() {
//         // Failed
//       }
//     )
//   }

//   getGasUtility(utilId) {
//     var _this = this
//     NeemWebAPIUtils.getUtility(utilId).then(
//       function(response, textStatus) {
//         if (textStatus === 'success') {
//           _this.dispatch(response)
//         }
//       },
//       function() {
//         // Failed
//       }
//     )
//   }

//   getUtility(primaryUtilId) {
//     var _this = this
//     NeemWebAPIUtils.getUtility(primaryUtilId).then(
//       function(response, textStatus) {
//         if (textStatus === 'success') {
//           _this.dispatch(response)
//         }
//       },
//       function() {
//         // Failed
//       }
//     )
//   }

//   getHomeUpgrade(homeId) {
//     var _this = this
//     NeemWebAPIUtils.getHomeUpgrade(homeId).then(
//       function(response, textStatus) {
//         if (textStatus === 'success') {
//           _this.dispatch(response)
//         }
//       },
//       function() {
//         // Failed
//       }
//     )
//   }

//   getCertificate(homeId) {
//     var _this = this
//     NeemWebAPIUtils.getCertificate(homeId).then(
//       function(response, textStatus) {
//         if (textStatus === 'success') {
//           _this.dispatch(response)
//         }
//       },
//       function() {
//         // Failed
//       }
//     )
//   }

//   getHomeIncentive(primaryUtilId, brandID, certDate) {
//     var _this = this
//     NeemWebAPIUtils.getHomeIncentive(primaryUtilId, brandID, certDate).then(
//       function(response, textStatus) {
//         if (textStatus === 'success') {
//           _this.dispatch(response)
//         }
//       },
//       function() {
//         // Failed
//       }
//     )
//   }

//   getCertificateTest(homeId) {
//     return new Promise((resolve, reject) => {
//       // For real usage, consider matthew-andrews/isomorphic-fetch.
//       var xhr = new XMLHttpRequest()
//       xhr.open('GET', `/api/v1/db/cert/${homeId}`, true)
//       xhr.setRequestHeader('Content-type', 'application/json')

//       xhr.onreadystatechange = () => {
//         if (xhr.readyState !== 4) return
//         if (xhr.status === 200) {
//           this.dispatch(xhr.response)
//           //UserActions.saveUserResponse(xhr.response);
//           //resolve(fields);
//         }
//       }

//       // xhr.send(JSON.stringify(searchData));
//       xhr.send()
//     })
//   }
// }

// export default alt.createActions(new CertificateActions())

export function getCertificateTest(homeId) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", `/api/v1/db/cert/${homeId}`, true);
    xhr.setRequestHeader("Content-type", "application/json");

    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return;
      if (xhr.status === 200) {
        // this.dispatch(xhr.response)
        resolve(xhr.response);
        //UserActions.saveUserResponse(xhr.response);
        //resolve(fields);
      }
    };

    // xhr.send(JSON.stringify(searchData));
    xhr.send();
  });
}
