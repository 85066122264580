export function convertObjectEmptyStringValuesToNull(obj) {
  let newObj = {}
  // Replace any "" with null
  for (let [key, val] of Object.entries(obj)) {
    if (val === '') {
      val = null
    }
    newObj[key] = val
  }
  return newObj
}

export const createQueryString = data => {
  return Object.keys(data)
    .map(key => {
      return [key, data[key]].map(encodeURIComponent).join('=')
    })
    .join('&')
}
