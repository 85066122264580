import NeemWebAPIUtils from "../../utils/NeemWebAPIUtils.js";

export const GET_RETAILERS = "GET_RETAILERS";
export function getRetailers() {
  return {
    type: GET_RETAILERS,
    payload: NeemWebAPIUtils?.getRetailers(),
  };
}

export const GET_MANUFACTURERS = "GET_MANUFACTURERS";
export function getManufacturers() {
  return {
    type: GET_MANUFACTURERS,
    payload: NeemWebAPIUtils.getManufacturers(),
  };
}

export const GET_HOMES = "GET_HOMES";
export function getHomes(searchData) {
  return {
    type: GET_HOMES,
    payload: NeemWebAPIUtils.getHomes(searchData),
  };
}

export const START_LOADING = "START_LOADING";
export function startLoading() {
  return {
    type: START_LOADING,
  };
}

export const RESET_HOMES = "RESET_HOMES";
export function resetHomes() {
  return {
    type: RESET_HOMES,
  };
}
