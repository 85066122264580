import Component from '../components/component.react.js'
import DocumentTitle from 'react-document-title'
import LoginForm from '../auth/login.react.js'
import React from 'react'
import messages from '../messages.js'

class Login extends Component {
  render() {
    const userIsLoggedIn = !!this.props.viewer

    return (
      <DocumentTitle title={messages.en.auth.title}>
        <div className="login-page">{!userIsLoggedIn && <LoginForm {...this.props} />}</div>
      </DocumentTitle>
    )
  }
}

export default Login
