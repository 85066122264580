import { Record } from 'immutable'

const HomeRecord = Record({
  lat: 0, // both lat and lng come from 'geom' field in PostGIS
  lng: 0,
  HomeID: '',
  ManufacturerID: '',
  RetailerID: '',
  HeatingSystemID: '',
  PathID: '',
  BrandID: '',
  HomeStatusID: '',
  ElecUtilityID: '',
  GasUtilityId: '',
  EnergySourceID: '',
  EnergySource: '',
  ModelNumber: '',
  SerialNumber: '',
  Sections: '',
  HomeSqFt: '',
  GlassDoorSqFt: '',
  SkylightSqFt: '',
  WindowSqFt: '',
  CertDate: '',
  SiteAddress: '',
  SiteCity: '',
  SiteState: '',
  SiteZip: '',
  SitePhone: '',
  SiteEmail: '',
  OwnerFN: '',
  OwnerLN: '',
  // Not part of Home table, but returned from homesearch endpoint
  /// Brand
  BrandDesc: '',
  /// Manufacturer
  ManufacturerName: '',
  /// Retailer
  RetailerName: '',
  RetailerAddress1: '',
  RetailerAddress2: '',
  RetailerCity: '',
  RetailerState: '',
  RetailerZip: '',
  /// HomeStatus
  HomeStatus: '',
  /// HomeType
  HomeType: '',
  HomeTypeID: '',
  /// HeatingSystem
  HeatingSystem: '',
  /// Path
  PathDescription: '',
  /// Utility - /homesearch joins to Utility twice, for Electric and Gas
  ElectricUtilityName: '',
  GasUtilityName: '',

  electric_utility_names_intersected: null,
})

export default class Home extends HomeRecord {
  constructor(params) {
    const paramsToUse = { ...params }
    if (params.CertDate) {
      // Remove any time code included after the date
      const tPos = params.CertDate.indexOf('T')
      if (tPos) {
        const newCertDate = params.CertDate.slice(0, tPos)
        paramsToUse.CertDate = newCertDate
      }
    }
    super(paramsToUse)
  }

  get id() {
    return this.HomeID
  }
}
