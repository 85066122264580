import { Parser } from "json2csv";
import { PREDICTED_UTILITIES_SEPARATOR } from "../constants.js";

export function exportHomesCSV(
  homes,
  retailer,
  manufacturer,
  fromDate,
  toDate
) {
  console.log("Generating CSV...");
  // const fields = Object.keys(homes[0]);

  let predictedElectricUtilitiesFieldCount = 0;

  const homesWithPredictedUtilities = homes.map((home) => {
    if (!home.electric_utility_names_intersected) return home;

    let numUtilities = 0;
    for (const predictedUtilityName of home.electric_utility_names_intersected.split(
      PREDICTED_UTILITIES_SEPARATOR
    )) {
      numUtilities++;
      home[`PredictedElectricUtilityName${numUtilities}`] =
        predictedUtilityName;
    }

    if (numUtilities > predictedElectricUtilitiesFieldCount) {
      predictedElectricUtilitiesFieldCount = numUtilities;
    }

    return home;
  });

  // console.log("homesWithPredictedUtilities", homesWithPredictedUtilities);

  const fields = [
    "HomeID",
    "CertDate",
    "ManufacturerName",
    "SerialNumber",
    "BrandDesc",
    "RetailerName",
    "RetailerAddress1",
    "RetailerAddress2",
    "RetailerCity",
    "RetailerState",
    "RetailerZip",
    "SiteAddress",
    "SiteCity",
    "SiteState",
    "SiteZip",
    "SitePhone",
    "SiteEmail",
    "OwnerFN",
    "OwnerLN",
    "HomeStatus",
    "ElectricUtilityName",
    "GasUtilityName",
    "HomeType",
    "HeatingSystem",
    "PathDescription",
    "EnergySource",
    "ModelNumber",
    "Sections",
    "HomeSqFt",
    "GlassDoorSqFt",
    "SkylightSqFt",
    "WindowSqFt",
    "lat",
    "lng",
  ];
  // .concat(
  //   Array(predictedElectricUtilitiesFieldCount)
  //     .fill()
  //     .map((_, i) => `PredictedElectricUtilityName${i + 1}`)
  // );

  let fileName = "NEEMHomes";
  if (retailer) fileName += "_" + retailer;
  if (manufacturer) fileName += "_" + manufacturer;
  if (fromDate && toDate) fileName += "_" + fromDate + "--" + toDate;
  fileName += ".csv";

  fileName = fileName.replace(/\s/g, "");
  // console.log("filename", fileName);

  const json2csvParser = new Parser({fields});
  const csv = json2csvParser.parse(homesWithPredictedUtilities);
  // console.log("csv", csv);

  const blob = new Blob([csv], { type: "text/csv" });
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // json2csv({ data: homesWithPredictedUtilities, fields: fields }, (err, csv) => {
  //   if (err) {
  //     console.log("Error", err)
  //     throw err
  //   }
  //   var a = window.document.createElement('a')
  //   a.href = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
  //   a.download = fileName

  //   // Append anchor to body, so we can auto-click it to start download
  //   document.body.appendChild(a)
  //   a.click()

  //   // Remove anchor from body
  //   document.body.removeChild(a)
  // })
}
