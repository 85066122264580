import "./login.css";
import { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signInSuccess } from "../redux/actions/auth.js";
import immutable from "immutable";
import messages from "../messages.js";

const LoginForm = ({ signInSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const fields = {
      email,
      password,
    };

    try {
      const res = await axios.post("/login", fields);
      // console.log("res.data", res.data);
      signInSuccess(res.data);
      const nextPath = new URLSearchParams(window.location.search).get(
        "nextPath"
      );
      // console.log(
      //   "nextPath",
      //   new URLSearchParams(window.location.search).get("nextPath")
      // );
      navigate(nextPath || "/");
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="login">
      <form onSubmit={onFormSubmit}>
        <fieldset>
          <legend>{messages.en.auth.form.legend}</legend>
          <input
            autoFocus
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={messages.en.auth.form.placeholder.email}
            value={email}
          />
          <br />
          <input
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder={messages.en.auth.form.placeholder.password}
            type="password"
            value={password}
          />
          <br />
          <button type="submit">{messages.en.auth.form.button.login}</button>
          {error && <span className="error-message">{error.message}</span>}
          <div>{messages.en.auth.form.hint}</div>
        </fieldset>
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  auth: PropTypes.instanceOf(immutable.Map),
  signInSuccess: PropTypes.func.isRequired,
};

export default connect(undefined, { signInSuccess })(LoginForm);
